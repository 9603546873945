import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#1f7094',
            darker: '#053e85',
        },
        secondary: {
            main: '#5db7cc'
        },
        accent: {
            main: '#9a5860'
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        dark: {
            main: '#1a1a26'
        },
        light: {
            main: '#faf9f9'
        }
    },
})
