import React, { useContext, useState, useRef } from 'react'
import { Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Container from '@mui/material/Container'
import { filesize } from 'filesize'
import { uploadFiles, downloadFiles, deleteFiles, sendNewUploadEmail } from '../utils/globalFunctions'
import { useSnackbar } from 'notistack'
import { websiteContext } from '../contexts/websiteContext'

const columns = [
	{
		field: 'name',
		headerName: 'Filename',
		width: 500,
		editable: false,
	},
	{
		field: 'createdOn',
		headerName: 'Created date',
		width: 200,
		editable: false,
		valueFormatter: (date) => date.value.toLocaleString('en-US')
	},
	{
		field: 'lastModified',
		headerName: 'Modified date',
		width: 200,
		editable: false,
		valueFormatter: (date) => date.value.toLocaleString('en-US')
	},
	{
		field: 'contentLength',
		headerName: 'File size',
		type: 'string',
		width: 100,
		valueFormatter: (params) => filesize(params.value, {base: 2, standard: 'jedec', round: 1})
	}
]

const ClientFiles = ({rows, containerClient, setFiles, setLoading, cutOffDays, viewHeight, userData = null}) => {
	const { enqueueSnackbar } = useSnackbar()
	const [ selectionModel, setSelectionModel ] = useState([])
	const inputFileRef = useRef(null)
	const [ state ] = useContext(websiteContext)

	const showFileDialog = () => inputFileRef.current && inputFileRef.current.click()

	const selectedRows = (newSelectionModel) => {
		setSelectionModel(newSelectionModel)
	}

	const downloadButton = () => {
		const files = rows.filter((row) => selectionModel.includes(row.id)).map((row) => row)
		downloadFiles(files, containerClient, setLoading)
		// enqueueSnackbar(`File${(files.length === 1 ? '' : 's')} downloaded!`, {variant: 'success'})
	};

	const deleteButton = () => {
		const files = rows.filter((row) => selectionModel.includes(row.id)).map((row) => row.name)

		const confirmAnswer = window.confirm(`Are you sure you wish to delete ${(selectionModel.length === 1 ? 'this file' : 'these files')}? \r\n\r\n${files.join('\r\n')}`)
		if (confirmAnswer) {
		deleteFiles(files, containerClient, setFiles, setLoading, cutOffDays)
		enqueueSnackbar(`File${(files.length === 1 ? '' : 's')} deleted!`, {variant: 'success'})
		}
	};

	const uploadFileButton = async (files) => {
		const fileResult = files && await uploadFiles(files, containerClient, setFiles, setLoading, cutOffDays)
		var uploaderName, uploaderEmail, recipientName, recipientEmail
		if (userData) {
			uploaderName = 'Liss & Andrews, P.C.'
			recipientName = userData.name
			recipientEmail = userData.email
		} else {
			uploaderName = state.userName
			uploaderEmail = state.userEmail
			recipientName = 'Web Portal Admin'
			recipientEmail = process.env.REACT_APP_DEFAULT_LA_FIRM_RECIPIENT_ADDRESS
		}
		fileResult.forEach((file) => {
			// Variables to send: uploaderName, uploaderEmail (optional), recipientName, recipientEmail, fileName, file.url, portalURI
			sendNewUploadEmail(uploaderName, uploaderEmail, recipientName, recipientEmail, file._name, file.url, process.env.REACT_APP_PORTAL_URI)
		})
		enqueueSnackbar(`File${(files.length === 1 ? '' : 's')} uploaded!`, {variant: 'success'})
	};

	return (
		<Container sx={{ marginTop: '1em' }}>
			<input
				style={{ display: 'none' }}
				ref={inputFileRef}
				type="file"
				multiple={true}
				onChange={e => uploadFileButton(e.target.files)}
			/>
			<Button variant='contained' color="success" component="label" onClick={showFileDialog} sx={{ margin: '0.2em' }} >Add New Files</Button>
			<Button variant="contained" color="info" disabled={(selectionModel.length === 0 ? true : false)} onClick={downloadButton} sx={{ margin: '0.2em' }} >Download</Button>
			<Button variant='contained' color='error' disabled={(selectionModel.length === 0 ? true : false)} onClick={deleteButton} sx={{ margin: '0.2em' }} >Delete</Button>
			<Box sx={{ height: viewHeight}}>
				<DataGrid
				rows={rows}
				columns={columns}
				checkboxSelection
				disableSelectionOnClick
				loading={false}
				autoPageSize
				pagination
				sortingOrder={['desc', 'asc']}
				initialState={{
					sorting: {
					sortModel: [{ field: 'createdOn', sort: 'desc' }],
					},
				}}
				onRowSelectionModelChange={(newSelectionModel) => selectedRows(newSelectionModel)}
				rowSelectionModel={selectionModel}
				/>
			</Box>
		</Container>
	)
}

export default ClientFiles
