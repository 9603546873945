import { createContext, useState } from 'react'
import INITIAL_CONTEXT_VALUES from '../constants/initialContextValues'

const websiteContext = createContext([
    INITIAL_CONTEXT_VALUES,
    () => {}
])

const WebsiteContextProvider = ({ children }) => {
    const [state, setState] = useState(INITIAL_CONTEXT_VALUES)
    return (
        <websiteContext.Provider value={[state, setState]}>
            {children}
        </websiteContext.Provider>
    )
}

export { websiteContext, WebsiteContextProvider }
