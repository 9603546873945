import React, { useContext, useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { createUser, listUsers } from '../actions/adminAppActions'
import { websiteContext } from '../../../contexts/websiteContext'
import { useSnackbar } from 'notistack'
import userTypes from '../../../constants/userTypes'
import { capitalizeName } from '../../../utils/capitalizeName'

const CreateUser = () => {
    const [ state ] = useContext(websiteContext)
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ userType, setUserType ] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

    const registerUser = async (e) => {
        e.preventDefault()
        
        if (firstName && lastName && email && userType) {
            const clients = await listUsers(state.sasUri)
            const emailExists = clients.filter((client) => client.email === email)
            if (emailExists.length === 0) {
                const res = await createUser(state.sasUri, {
                    firstName,
                    lastName,
                    name: `${firstName} ${lastName}`,
                    email,
                    userType,
                    status: 'active'
                })
                if (res) {
                    setFirstName('')
                    setLastName('')
                    setEmail('')
                    setUserType(null)
                    enqueueSnackbar('User created!', {variant: 'success'})
                } else {
                    enqueueSnackbar('User account already created! Contact support if user files cannot be loaded!', {variant: 'error'})
                }
            } else {
                enqueueSnackbar('Email already exists in system! User not added!', {variant: 'warning'})
            }
        } else {
            enqueueSnackbar('Required info not supplied!', {variant: 'error'})
        }
    }

    return (
        <Container sx={{ marginTop: '1em', backgroundColor: 'white', padding: '1em', borderRadius: '0.5em' }}>
            <Box component='form' validate sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400, width: '100%'}}>
                <Grid container spacing={2} >
                    <Grid item sm={12}>
                        <Typography variant='h6' sx={{textAlign: 'center'}}>
                            In order to register a new user, please fill out the required information:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item>
                                <TextField required label='First Name' variant='standard' value={firstName} onChange={(e) => setFirstName(capitalizeName(e.target.value))} />
                            </Grid>
                            <Grid item>
                                <TextField required label='Last Name' variant='standard' value={lastName} onChange={(e) => setLastName(capitalizeName(e.target.value))} />
                            </Grid>
                            <Grid item>
                                <TextField required label='Email' variant='standard' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' >
                        <Grid container spacing={2} sx={{marginTop: '1em'}} justifyContent='center'>
                            <Grid item >
                                <Autocomplete
                                    sx={{width: '11em'}}
                                    value={userType}
                                    options={userTypes}
                                    renderInput={(params) => <TextField {...params} required variant='standard' label="User Type" />}
                                    onChange={(event, newValue) => setUserType(newValue)}
                                />
                            </Grid>
                            <Grid item>
                                <Button type='submit' variant='contained' sx={{marginTop: '1em'}} endIcon={<SendIcon />} onClick={(e) => registerUser(e)} >Register</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default CreateUser
