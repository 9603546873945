import React, { useContext, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import { websiteContext } from '../contexts/websiteContext'
import { stringAvatar } from '../utils/globalFunctions'

const Navbar = ({ authMethod, instance }) => {
    const { logout } = useAuth0()

    const [ state ] = useContext(websiteContext)
    const [ anchorElUser, setAnchorElUser ] = useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleLogout = async () => {
        await instance.handleRedirectPromise()
        await instance.logoutRedirect({ postLogoutRedirectUri: 'https://lissfirm.com' }) // Doesn't seem to work; I think I need a logout endpoint with a redirect
    }

    return (
        <AppBar position="static" color='light' >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src='/LA_Logo_23_Vertical_RGB.png' height='75' alt='LA Logo' style={{marginTop: '0.75em', marginBottom: '0.75em'}} />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }} />
                    <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title='Open settings'>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar {...stringAvatar(state.userName)} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id='menu-appbar'
                        sx={{ mt: '45px' }}
                        keepMounted
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem key='username' disabled={true} >
                            <Typography textAlign='center'>
                                {state.userName}
                            </Typography>
                        </MenuItem>
                        <MenuItem key='useremail' disabled={true} >
                            <Typography textAlign='center'>
                                {state.userEmail}
                            </Typography>
                        </MenuItem>
                        <Divider />
                        {authMethod === 'Auth0' && 
                            <MenuItem key='logoutAuth0' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                <Typography textAlign='center'>
                                    Log Out
                                </Typography>
                            </MenuItem>
                        }
                        {authMethod === 'MSAL' && 
                            <MenuItem key='logoutMsal' onClick={handleLogout}>
                                <Typography textAlign='center'>
                                    Log Out
                                </Typography>
                            </MenuItem>
                        }
                    </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar
