export function randomString(length, includeNumbers = true) {
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numbers = '0123456789'
    const randomCharacter = (choices) => {
        return choices[Math.floor(Math.random() * choices.length)]
    }
    var result = ''
    if (includeNumbers) {
        for (var i = length-4; i > 0; --i) result += randomCharacter(letters)
        for (var j = 4; j > 0; --j) result += randomCharacter(numbers)
    } else {
        for (var k = length; k > 0; --k) result += randomCharacter(letters)
    }
    return result
}