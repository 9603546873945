import { BlobServiceClient } from '@azure/storage-blob'
import { randomString } from '../../../utils/randomString'

const reactAppUri = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URI_DEV : process.env.REACT_APP_API_URI)

export const getAccountSasUri = async () => {
	try {
		const sasUri = await fetch(
			`${reactAppUri}/getAccountSasUri`,
			{
				method: 'GET',
			}
		).then((res) => res.json())
		return sasUri
	} catch (error) {
		console.error(error)
	}
}

export const listUsers = async (sasUri, showAll = true) => {
	let users = [];
    const blobServiceClient = new BlobServiceClient(sasUri);
	if (sasUri) {
		for await (const container of blobServiceClient.listContainers({includeMetadata: true})) {
            const results = {...container.metadata, ...container.properties, id: container.name}
			if (!container.metadata.hasOwnProperty('status')) {
				results.status = 'inactive'
			}
			if (showAll || (!showAll && container.metadata.hasOwnProperty('status') && container.metadata.status === 'active')){
				users.push(results)
			}
		}
		return users
	}
}

export const createB2CUser = async (metadata) => {
	const payload = {
		"email": metadata.email,
		"given_name": metadata.firstName,
		"family_name": metadata.lastName,
		"name": metadata.name,
		"password": randomString(20) // Generates random initial password
	}
	const res = await fetch(`${reactAppUri}/B2CUser`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	})
	if (res.ok) {
		const data = await res.json()
		const response = {
			...data,
			...payload
		}
		return response
	} else {
		console.error(res)
		return false
	}
}

export const sendNewUserEmail = async (recipientName, recipientEmail, portalURI, initialPassword) => {
	const payload = {
		recipientName,
		recipientEmail,
		portalURI,
		initialPassword
	}
	const res = await fetch(`${reactAppUri}/newUserEmail`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	})
	if (res.ok) {
		res.json()
		return res
	} else {
		return false
	}
}

export const createUser = async (sasUri, metadata) => {
	// Metadata object must include:
	// {
	// 	firstName,
	// 	lastName,
	//  name,
	// 	email,
	// 	userType,
	// 	status: 'active' | 'inactive' | 'archived'
	// }
	if (sasUri) {
		const res = await createB2CUser(metadata)
		if (res) {
			const blobServiceClient = new BlobServiceClient(sasUri)
			const containerName = res.id
			const containerClient = blobServiceClient.getContainerClient(containerName)
			const createContainerResponse = await containerClient.create()
			
			if (!createContainerResponse.errorCode) {
				const metadataSetResponse = await containerClient.setMetadata(metadata)
				if (!metadataSetResponse.errorCode) {
					sendNewUserEmail(res.name, res.email, process.env.REACT_APP_PORTAL_URI, res.password)
					return true
				} else {
					console.error(metadataSetResponse.errorCode)
					return false
				}
			}
		} else {
			console.error(res)
			return false
		}

	}
}

export const updateBlobUserInfo = async (sasUri, metadata, userId) => {
	// Metadata object must include:
	// {
	// 	firstName,
	// 	lastName,
	// 	email,
	// 	userType,
	//  status: 'active' | 'inactive' | 'archived'
	// }
	if (sasUri) {
		const blobServiceClient = new BlobServiceClient(sasUri);
		const containerName = userId
		const containerClient = blobServiceClient.getContainerClient(containerName)

		const metadataSetResponse = await containerClient.setMetadata(metadata)
		if (metadataSetResponse.errorCode) {
			console.error(metadataSetResponse.errorCode)
			return false
		}
		// TODO: Need to improve response
		return metadataSetResponse
	}
}

export const updateB2CAccess = async (status, userId) => {
	// If status change is active <-> inactive, must update B2C
	// If status change is inactive <-> archived, must update Blob

	const res = await fetch(`${reactAppUri}/B2CUser?userId=${userId}&status=${status}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		}
	})
	if (res.ok) {
		return true
	} else {
		console.error(res.status)
		return false
	}
}

export const deleteUser = async (sasUri, userId) => {
	if (sasUri && userId) {
		const blobServiceClient = new BlobServiceClient(sasUri)
		const containerClient = blobServiceClient.getContainerClient(userId)
		const createContainerResponse = await containerClient.delete()

		if (!createContainerResponse.errorCode) {
			const deleteResponse = await deleteB2CUser(userId)
			if (deleteResponse) {
				return true
			} else {
				console.error(deleteResponse)
				return false
			}
		} else {
			console.error(createContainerResponse)
			return false
		}
	} else {
		return false
	}
}

export const deleteB2CUser = async (userId) => {
	const res = await fetch(`${reactAppUri}/B2CUser?userId=${userId}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		}
	})
	if (res.ok) {
		return true
	} else {
		console.error(res.status)
		return false
	}
}