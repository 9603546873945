import React from 'react'
import { Button, Container } from '@mui/material'

const Home = () => {
	return (
		<Container style={{position: 'absolute', top: '5%', transform: 'translateY(-50%)'}}>
			<Button variant='outlined' href='/portal'>User Portal</Button>
			<Button color='secondary' variant='contained' href='/portalAdmin' >Admin Portal</Button>
		</Container>
	)
}

export default Home
