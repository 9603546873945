import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import ClientFiles from '../../components/ClientFiles'
import { createContainerClientAndGetFiles } from '../../utils/globalFunctions'

const ClientApp = ({userData = null}) => {
  const [ containerClient, setContainerClient ] =  useState(null)
  const [ files, setFiles ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(true)
    createContainerClientAndGetFiles(userData.id, setContainerClient, setFiles)
    setLoading(false)
    // eslint-disable-next-line
  }, [userData])

  return (
    <>
      {(files ? <ClientFiles rows={files} containerClient={containerClient} setFiles={setFiles} setLoading={setLoading} viewHeight={'calc(100vh - 200px)'} userData={userData} /> : null)}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ClientApp;
