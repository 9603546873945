import { loginRequest, graphConfig } from '../services/authConfig'

export async function callMsGraph(instance, accessToken = null) {
    if (!accessToken) {
        const account = instance.getActiveAccount()
        if (!account) {
            throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
        }
    
        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: account
        })
        accessToken = response.accessToken
    }

    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)

    const options = {
        method: 'GET',
        headers: headers
    }

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.error(error))
}
