import React from 'react'
import { Typography } from '@mui/material'

const Footer = () => {
    return (
        <Typography variant='p' sx={{color: 'white', marginLeft: '4em'}} >Copyright {new Date().getFullYear()} Liss & Andrews, All Rights Reserved</Typography>
    )
}

export default Footer
