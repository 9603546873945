import React, { useEffect, useContext } from 'react'

import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react'
import { EventType, InteractionStatus, InteractionType } from '@azure/msal-browser'

import { B2CLoginRequest, b2cPolicies } from '../services/B2CAuthConfig'
import { compareIssuingPolicy } from '../utils/claimUtils'

import { websiteContext } from '../contexts/websiteContext'
import Navbar from './Navbar'
import Footer from './Footer'

const B2CAuthenticatedRoutes = ({ component }) => {
    const { instance, inProgress } = useMsal()
    const [ state, setState ] = useContext(websiteContext)

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                    // retrieve the account from initial sign-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                account.idTokenClaims['tfp'] === b2cPolicies.names.SignIn
                        )

                    let signInFlowRequest = {
                        authority: b2cPolicies.authorities.SignIn.authority,
                        account: originalSignInAccount,
                    }

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signInFlowRequest)
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signInFlowRequest = {
                        authority: b2cPolicies.authorities.SignIn.authority,
                    }
                    instance.loginRedirect(signInFlowRequest)
                }
            }
            // && event.error.errorMessage.includes('AADB2C90118')
            if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
                console.error(event.error.errorMessage)
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.forgotPassword.authority,
                    scopes: [],
                };
                instance.loginRedirect(resetPasswordRequest)
            }
        })

        
        if (inProgress === InteractionStatus.None) {
            const accounts = instance.getAllAccounts().filter((accounts) => {
                return accounts.environment === 'lissandrews.b2clogin.com'
            })
            const account = accounts[0]
            
            if (account) {
                instance.setActiveAccount(account)
                setState({
                    ...state,
                    userName: account.name,
                    userEmail: account.username,
                    id: account.localAccountId
                })
            }
        }

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
        // eslint-disable-next-line
    }, [instance, inProgress])

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={B2CLoginRequest}
        >
            <div style={{height: '100vh', backgroundImage: 'url(/proofs-1489-w-blue-2.jpg)', position: 'fixed', top: 0, left: 0, width: '100vw'}}>
                <div style={{height: 'calc(100vh - 30px)'}}>
                    <Navbar authMethod={'MSAL'} instance={instance} />
                    {component}
                </div>
                <Footer />
            </div>
        </MsalAuthenticationTemplate>
    )
}

export default B2CAuthenticatedRoutes
