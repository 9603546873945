import React, { useEffect, useContext } from 'react'

import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'

import { loginRequest } from '../services/authConfig'

import { callMsGraph } from '../utils/msGraphApiCall'

import { websiteContext } from '../contexts/websiteContext'
import Navbar from './Navbar'
import Footer from './Footer'
import RouterBreadcrumbs from './RouterBreadcrumbs'

const MSALAuthenticatedRoutes = ({ component }) => {
    const { instance, inProgress } = useMsal()
    const [ state, setState ] = useContext(websiteContext)
    // Need to protect for B2C accounts trying to access this
    // Might be able to use "iss" from the logged in account
    // to determine if it's from the right tenant
    
    const handleRedirect = async () => {
        await instance.handleRedirectPromise()
        await instance.loginRedirect()
    }
    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            const accounts = instance.getAllAccounts().filter((account) => {
                return account.environment === 'login.windows.net'
            })
            const account = accounts[0]
            if (account) {
                instance.setActiveAccount(account)
                callMsGraph(instance).then((res) => {
                    setState({
                        ...state,
                        userName: res.displayName,
                        userEmail: res.userPrincipalName,
                        id: res.id
                    })
                })
            } else {
                console.log('redirecting')
                handleRedirect()
            }
        }
        // eslint-disable-next-line
    }, [inProgress])

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
        >
            <div style={{height: '100vh', backgroundImage: 'url(/proofs-1489-w-blue-2.jpg)', position: 'fixed', top: 0, left: 0, width: '100vw'}}>
                <div style={{height: 'calc(100vh - 30px)'}}>
                    <Navbar authMethod={'MSAL'} instance={instance} />
                    <div style={{paddingTop: '2em'}} />
                    <RouterBreadcrumbs />
                    {component}
                </div>
                <Footer />
            </div>
        </MsalAuthenticationTemplate>
    )
}

export default MSALAuthenticatedRoutes
