import React, { useContext, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PeopleIcon from '@mui/icons-material/People'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { websiteContext } from '../../contexts/websiteContext'
import { getAccountSasUri } from './actions/adminAppActions'

const UserControlCard = ({control}) => {
    return (
        <Link to={control.url} underline='none' component={RouterLink}>
            <Card>
                <CardContent>
                    <Typography color='primary' variant='h5'>
                        {control.title}
                    </Typography>
                    {control.icon}
                </CardContent>
            </Card>
        </Link>
    )
}

const AdminApp = () => {
    const [ state, setState ] = useContext(websiteContext)
    const userControls = [
        {
            title: 'Portal Users',
            icon: <PeopleIcon color='primary' style={{ fontSize: 100 }} />,
            url: '/portalAdmin/users'
        },
        {
            title: 'Create Users',
            icon: <PersonAddIcon color='primary' style={{ fontSize: 100 }} />,
            url: '/portalAdmin/createUser'
        }
    ]

    useEffect(() => {
        const fetchSasUri = async () => {
            const sasUri = await getAccountSasUri()

            const sasArgsTemp = decodeURIComponent(sasUri).split('?')[1].split('&')
            const sasArgs = sasArgsTemp.reduce((accumulator, arg) => {
                const [ key, value ] = arg.split('=')
                accumulator[key] = value
                return {
                    ...accumulator
                }
            }, {})

            console.log(sasArgs)
            setState({
                ...state,
                sasUri 
            })
            window.sessionStorage.setItem('sasUri', sasUri)
        }
        if (state.id) {
            fetchSasUri()
        }
        // eslint-disable-next-line
    }, [state.id])

    return (
        <Container style={{marginTop: '1em'}}>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2 }} justifyContent='center' >
                {userControls.map((control) => {
                    return (
                        <Grid item style={{ width: '200px' }} textAlign={'center'} key={control.title}>
                            <UserControlCard control={control} />
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default AdminApp
