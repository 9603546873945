import React from 'react'
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'

// import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './services/msalInstance'
import { B2CMsalInstance } from './services/B2CMsalInstance'
// import { msalConfig } from './services/authConfig'
// import { B2CMsalConfig } from './services/B2CAuthConfig'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'

import { SnackbarProvider } from 'notistack'


import { WebsiteContextProvider } from './contexts/websiteContext'

import MSALAuthenticatedRoutes from './components/MSALAuthenticatedRoutes'
import B2CAuthenticatedRoutes from './components/B2CAuthenticatedRoutes'
import MissingRoute from './components/MissingRoute'

import Home from './applications/homeApp'
import ClientApp from './applications/clientApp'
import AdminApp from './applications/adminApp'
import CreateUser from './applications/adminApp/components/CreateUser'
import ListUsers from './applications/adminApp/components/ListUsers'


// export const msalInstance = new PublicClientApplication(msalConfig)
// export const B2CMsalInstance = new PublicClientApplication(B2CMsalConfig)

const App = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/portal',
            element: 
                <MsalProvider instance={B2CMsalInstance}>
                    <B2CAuthenticatedRoutes component={<Outlet />} />
                </MsalProvider>,
            children: [
                {
                    path: '',
                    element: <ClientApp />
                }
            ],
            errorElement: <MissingRoute />
        },
        {
            path: '/portalAdmin',
            element:
                <MsalProvider instance={msalInstance}>
                    <MSALAuthenticatedRoutes component={<Outlet />} />
                </MsalProvider>,
            children: [
                {
                    path: '',
                    element: <AdminApp />
                },
                {
                    path: 'users',
                    element: <ListUsers />
                },
                {
                    path: 'createUser',
                    element: <CreateUser />
                },
            ],
            errorElement: <MissingRoute />
        },
        {
            path: '*',
            element: <MissingRoute />
        }
    ])

    return (
        <ThemeProvider theme={theme}>
            <WebsiteContextProvider>
                <SnackbarProvider maxSnack={3} autoHideDuration={4000} >
                    <RouterProvider router={router} />
                </SnackbarProvider>
            </WebsiteContextProvider>
        </ThemeProvider>
    )
}

export default App
