import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { B2CMsalConfig } from './B2CAuthConfig'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/

export const B2CMsalInstance = new PublicClientApplication(B2CMsalConfig)

// Default to using the first account if no account is active on page load
if (!B2CMsalInstance.getActiveAccount() && B2CMsalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    B2CMsalInstance.setActiveAccount(B2CMsalInstance.getAllAccounts()[0])
}

B2CMsalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        B2CMsalInstance.setActiveAccount(event.payload.account)
    }
})
