import React, { useEffect, useState, useContext } from 'react'
import { Backdrop, CircularProgress, Container, Typography } from '@mui/material'
import ClientFiles from '../../components/ClientFiles'
import { createContainerClientAndGetFiles } from '../../utils/globalFunctions'
import { websiteContext } from '../../contexts/websiteContext'

const ClientApp = ({userData = null}) => {
  const [ state ] = useContext(websiteContext)
  const [ containerClient, setContainerClient ] =  useState(null)
  const [ files, setFiles ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ cutOffDays ] = useState(30)

  useEffect(() => {
    if (state.id) {
      setLoading(true)
      createContainerClientAndGetFiles(state.id, setContainerClient, setFiles, cutOffDays)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [state.id, userData])

  return (
    <Container sx={{ backgroundColor: 'white', padding: '1em', borderRadius: '0.5em', marginTop: '1em', height: 'calc(95vh - 130px)'}}>
      <Typography variant='h6' align='center' style={{marginTop: '1em'}} >Note: Files will only be available {cutOffDays} days after they were uploaded!</Typography>
      {(files ? <ClientFiles rows={files} containerClient={containerClient} setFiles={setFiles} setLoading={setLoading} cutOffDays={cutOffDays} viewHeight={'calc(95vh - 290px)'} /> : null)}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  )
}

export default ClientApp
