import * as React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import {
    Link as RouterLink,
    useLocation,
} from 'react-router-dom'

const breadcrumbNameMap = {
        '/portal': 'PORTAL HOME',
        '/portalAdmin': 'PORTAL HOME',
        '/portalAdmin/users': 'PORTAL USERS',
        '/portalAdmin/createUser': 'CREATE USER'
    };

const ListItemLink = (props) => {
    const { to, open, ...other } = props
    const primary = breadcrumbNameMap[to]

    let icon = null
    if (open != null) {
        icon = open ? <ExpandLess /> : <ExpandMore />
    }

    return (
        <li>
            <ListItem component={RouterLink} to={to} {...other}>
                <ListItemText primary={primary} />
                {icon}
            </ListItem>
        </li>
    )
}

ListItemLink.propTypes = {
    open: PropTypes.bool,
    to: PropTypes.string.isRequired,
}

const LinkRouter = (props) => {
    return <Link {...props} component={RouterLink} />
}

const RouterBreadcrumbs = () => {
    const location = useLocation()
    const pathnames = location.pathname.split('/').filter((x) => x)

    return (
        <Container>
            <Breadcrumbs separator="›" color='white' aria-label="breadcrumb">
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`

                    return last ? (
                        <Typography variant='h5' color="white" key={to}>
                            {breadcrumbNameMap[to]}
                        </Typography>
                        ) : (
                        <LinkRouter underline="hover" variant='h5' color="white" to={to} key={to}>
                            {breadcrumbNameMap[to]}
                        </LinkRouter>
                    )
                })}
            </Breadcrumbs>
        </Container>
    )
}

export default RouterBreadcrumbs
