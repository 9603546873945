import React, { useContext, useEffect, useState } from 'react'
import { Box, FormControlLabel, Modal, Container, Menu, MenuItem, Switch, Tooltip } from '@mui/material'
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import SettingsIcon from '@mui/icons-material/Settings'
import {ThumbUpOffAlt, LockPersonOutlined, Inventory2Outlined} from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { deleteUser, listUsers, updateBlobUserInfo, updateB2CAccess } from '../actions/adminAppActions'
import ClientApp from '../clientApp'
import { websiteContext } from '../../../contexts/websiteContext'
import userTypes from '../../../constants/userTypes'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '0.5em'
}

const ListUsers = () => {
    const [ state, setState ] = useContext(websiteContext)
    const [ users, setUsers ] = useState([])
    const [ modalEnable, setModalEnable ] = useState(false)
    const [ userData, setUserData ] = useState(null)
    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ showAll, setShowAll ] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<SettingsIcon />}
                    label='Role'
                    onClick={(event) => editMenuOpen(event, params.row)}
                />
            ]
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 150,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
            editable: false,
        },
        {
            field: 'userType',
            headerName: 'User Type',
            width: 150,
            editable: false,
        },
        {
            field: 'lastModified',
            headerName: 'Last Modified',
            width: 200,
            editable: false,
            valueFormatter: (params) => params.value.toLocaleString()
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            editable: false,
            renderCell: (params) => {
                switch (params?.value) {
                    case 'active':
                        return <Tooltip title='Active'><ThumbUpOffAlt color='success' /></Tooltip>
                    case 'inactive':
                        return <Tooltip title='Inactive'><LockPersonOutlined color='error' /></Tooltip>
                    case 'archived':
                        return <Tooltip title='Archived'><Inventory2Outlined color='disabled' /></Tooltip>
                    default:
                        break
                }
            }
        }
    ]

    const ActiveUserEditMenu = () => {
        const [ subMenuAnchorEl, setSubMenuAnchorEl ] = useState(null)

        const handleMenuOpen = (event) => {
            setSubMenuAnchorEl(event.currentTarget)
        }
    
        const handleMenuClose = () => {
            setSubMenuAnchorEl(null)
        }

        return (
            <div>
                <MenuItem onClick={handleMenuOpen}>Change User Type</MenuItem>
                <Menu
                    id='change-role-menu'
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    {userTypes.filter((userType) => userData && userType !== userData.userType).map((userType) => {
                        return <MenuItem key={userType} onClick={() => changeUserRole(userType)} >{userType}</MenuItem>
                    })}
                </Menu>
            </div>
        )
    }

    const editMenuOpen = (event, rowData) => {
        setUserData(rowData)
        setAnchorEl(event.currentTarget)
    }

    const editMenuClose = () => {
        setAnchorEl(null)
        setUserData(null)
    }

    const changeUserRole = async (userType) => {
        // Change user info in Blob Storage
        await updateBlobUserInfo(state.sasUri, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            status: userData.status,
            name: userData.name,
            userType
        }, userData.id)
        // TODO: Update table with new info - improve this! Unreliable!
        await fetchUsers()
        // TODO: Add message to notifications
        enqueueSnackbar(`Changed ${userData.firstName} ${userData.lastName} to ${userType}!`, {variant: 'success'})
        editMenuClose()
    }

    const activateUser = async () => {
        // inactive -> active
        // Enable user in Azure AD B2C
        await updateB2CAccess(true, userData.id)

        // TODO Need to prevent Azure Blob from getting updated if B2C doesn't change
        // Change user info in Blob Storage
        await updateBlobUserInfo(state.sasUri, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            name: userData.name,
            status: 'active',
            userType: userData.userType
        }, userData.id)
        await fetchUsers()
        enqueueSnackbar(`Activated ${userData.firstName} ${userData.lastName}!`, {variant: 'success'})
        editMenuClose()
    }

    const deactivateUser = async () => {
        // active -> inactive
        // Disable user in Azure AD B2C
        await updateB2CAccess(false, userData.id)

        // TODO Need to prevent Azure Blob from getting updated if B2C doesn't change
        // Change user info in Blob Storage
        await updateBlobUserInfo(state.sasUri, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            name: userData.name,
            status: 'inactive',
            userType: userData.userType
        }, userData.id)
        await fetchUsers()
        enqueueSnackbar(`Deactivated ${userData.firstName} ${userData.lastName}!`, {variant: 'warning'})
        editMenuClose()
    }

    
    const archiveUser = async () => {
        // inactive -> archived

        // Change user info in Blob Storage
        await updateBlobUserInfo(state.sasUri, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            name: userData.name,
            status: 'archived',
            userType: userData.userType
        }, userData.id)
        await fetchUsers()
        enqueueSnackbar(`Archived ${userData.firstName} ${userData.lastName}!`, {variant: 'warning'})
        editMenuClose()
    }

    const restoreUser = async () => {
        // archived -> inactive

        // TODO: Need to prevent Azure Blob from getting updated if B2C doesn't change
        // Change user info in Blob Storage
        await updateBlobUserInfo(state.sasUri, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            name: userData.name,
            status: 'inactive',
            userType: userData.userType
        }, userData.id)
        await fetchUsers()
        enqueueSnackbar(`Restored ${userData.firstName} ${userData.lastName}! User still needs to be activated!`, {variant: 'success'})
        editMenuClose()
    }

    const handleDeleteUser = async () => {
        const confirmAnswer = window.confirm(`Are you sure you wish to delete ${userData.firstName} ${userData.lastName} (${userData.email})?`)
        if (confirmAnswer) {
            const deleteSuccessful = await deleteUser(state.sasUri, userData.id)
            if (deleteSuccessful) {
                await fetchUsers()
                enqueueSnackbar(`Deleted ${userData.firstName} ${userData.lastName}!`, {variant: 'warning'})
            } else {
                enqueueSnackbar(`Issue deleting ${userData.firstName} ${userData.lastName}! Please contact support!`, {variant: 'error'})
            }
        }
        editMenuClose()
    }

    const openUserControl = (rowData) => {
        setUserData(rowData)
        setModalEnable(true)
    }

    const closeUserControl = () => {
        setUserData(null)
        setModalEnable(false)
    }

    const fetchUsers = async () => {
        let usersList
        if (!state.sasUri) {
            const sasUri = window.sessionStorage.getItem("sasUri")
            usersList = await listUsers(sasUri, showAll)
            setUsers(usersList)
            setState({
                ...state,
                sasUri
            })
            return
        }
        usersList = await listUsers(state.sasUri, showAll)
        setUsers(usersList)
    }

    useEffect(() => {
        fetchUsers()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchUsers()
        // eslint-disable-next-line
    }, [showAll])

    useEffect(() => {
        window.sessionStorage.setItem("sasUri", state.sasUri)
    }, [state.sasUri])

    return (
        <Container sx={{ marginTop: '1em', backgroundColor: 'white', padding: '1em', borderRadius: '0.5em' }}>
            <Box sx={{ height: 'calc(95vh - 225px)', width: '100%'}}>
                <DataGrid
                    rows={users}
                    columns={columns}
                    disableSelectionOnClick
                    loading={false}
                    autoPageSize
                    pagination
                    sortingOrder={['desc', 'asc']}
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: 'lastName', sort: 'asc' }
                            ]
                        },
                    }}
                    onRowClick={(rowData) => openUserControl(rowData.row)}
                    sx={{ height: 'calc(100% - 40px)' }}
                    slots={{ toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true
                        }
                    }}
                />
                <FormControlLabel control={<Switch checked={showAll} onChange={() => setShowAll(!showAll)} />} label='Show All' />
                <Menu
                    id='user-edit-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={editMenuClose}
                >
                    <ActiveUserEditMenu />
                    {userData && userData.status === 'active' ? <MenuItem onClick={deactivateUser} >Deactivate User</MenuItem> : (userData && userData.status === 'archived'? <MenuItem onClick={activateUser} disabled={true} >Activate User</MenuItem> : <MenuItem onClick={activateUser} >Activate User</MenuItem> ) }
                    {userData && userData.status === 'active' ? <MenuItem disabled={true} >Archive User</MenuItem> : (userData && userData.status === 'archived'? <MenuItem onClick={restoreUser} >Restore User</MenuItem> : <MenuItem onClick={archiveUser} >Archive User</MenuItem> ) }
                    {userData && userData.status === 'active' ? <MenuItem disabled={true} >Delete User</MenuItem> : <MenuItem onClick={handleDeleteUser} >Delete User</MenuItem> }
                </Menu>
            </Box>
            <Modal open={modalEnable} onClose={()=> closeUserControl()} >
                <Container sx={style} >
                    {userData && <ClientApp userData={userData}/>}
                </Container>
            </Modal>
        </Container>
    )
}

export default ListUsers
